<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataUserWeb"
            :loading="loading"
            :expanded.sync="expanded"
            :single-expand="singleExpand"
            show-expand
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mb-3">
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" align="right">
                    <v-btn
                      v-if="modify"
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="addUser"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah User
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                Permission Gudang :
                <span v-for="gudang in item.managed_gudang" :key="gudang.id">
                  &nbsp;{{ gudang.name }};
                </span>
              </td>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                small
                class="font-weight-bold error--text"
                v-if="item.status === '0'"
              >
                Disabled
              </v-chip>
              <v-chip small class="font-weight-bold primary--text" v-else>
                Active
              </v-chip>
            </template>

            <template v-slot:item.actions="{ index }">
              <v-btn
                v-if="modify"
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="edit(index)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <add-data
      :addDataDialog="addDataDialog"
      :roleList="roleList"
      :gudangList="gudangList"
      @closeAddDataDialog="closeAddDataDialog"
      @reloadDataAdd="reloadDataAdd"
    />

    <olah-data
      :olahDataDialog="olahDataDialog"
      :olahData="olahData"
      :roleList="roleList"
      :gudangList="gudangList"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadDataEdit="reloadDataEdit"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="450">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import olahData from "../userWeb/olahData.vue";
import { mapActions } from "vuex";
import axios from "axios";
import AddData from "./addData.vue";

export default {
  components: { olahData, AddData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: "Nama",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "Email",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "email",
      },
      {
        text: "Role",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "roles_name",
      },
      {
        text: "Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "data-table-expand",
      },
      {
        text: "Status",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "status",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataUserWeb: [],
    roleList: [],
    gudangList: [],
    olahDataDialog: false,
    olahData: {},
    addDataDialog: false,
    progress: false,
    popDialog: false,
    popTitle: "",
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      this.loading = true;
      axios
        .get("v1/admin/user_web/list?limit=500")
        .then((response) => {
          this.dataUserWeb = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.log(error.response);
          }
        });
    },
    getRoleList() {
      axios.get("v1/admin/role/list?limit=100").then((response) => {
        response.data.data.forEach((element) => {
          if (element.is_user_web === 1) {
            this.roleList.push(element);
          }
        });
      });
    },
    getGudangList() {
      axios.get("v1/user/gudang/list").then((response) => {
        this.gudangList = response.data.data;
      });
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    getDataById(value) {
      axios.get("v1/admin/user_web?id=" + value).then((response) => {
        const data = response.data.data;
        if (data.is_activated === 0) {
          data.is_activated = 2;
        }
        console.log(data);
        // this.olahData = data;
        // this.progress = false;
        // this.olahDataDialog = true;
      });
    },
    addUser() {
      this.addDataDialog = true;
    },
    edit(value) {
      this.progress = true;
      this.olahData = this.dataUserWeb[value];
      this.progress = false;
      this.olahDataDialog = true;
      //this.getDataById(value);
    },
    checkType(value) {
      if (value === 0) {
        this.typeAction = "new";
        this.olahDataTitle = "Tambah User Web";
        this.olahDataBtn = "Tambahkan";
        this.olahDataDialog = true;
      } else {
        this.getDataById(value);
        this.typeAction = "edit";
        this.olahDataTitle = "Edit User Web";
        this.olahDataBtn = "Edit & Simpan";
        this.olahDataDialog = true;
      }
    },
    closeOlahDataDialog() {
      this.dataUserWeb = [];
      this.getData();
      this.olahDataDialog = false;
      this.olahData = {};
    },
    closeAddDataDialog() {
      this.dataUserWeb = [];
      this.getData();
      this.addDataDialog = false;
    },
    reloadDataAdd() {
      this.popTitle = "Data User Web berhasil ditambahkan";
      this.popDialog = true;
    },
    reloadDataEdit() {
      this.popTitle = "Data User Web berhasil diupdate";
      this.popDialog = true;
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
  },

  mounted() {
    this.getData();
    this.getRoleList();
    this.getGudangList();
  },
};
</script>