<template>
  <div>
    <v-dialog v-model="olahDataDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          Edit User Web
        </v-card-title>

        <v-divider class="mx-5"></v-divider>

        <v-card-text class="pb-0 mt-3">
          <v-form ref="form" v-model="valid">
            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Nama
            </v-card-title>
            <v-text-field
              v-model="olahData.name"
              outlined
              dense
              :rules="[rules.required]"
              autocomplete="off"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Password
            </v-card-title>
            <v-text-field
              v-model="newPassword"
              outlined
              dense
              autocomplete="off"
              :rules="[rules.required, rules.minReq]"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Email
            </v-card-title>
            <v-text-field
              v-model="olahData.email"
              dense
              outlined
              :rules="[rules.required, rules.email]"
              autocomplete="off"
            >
            </v-text-field>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Role
            </v-card-title>
            <v-select
              v-model="olahData.role_id"
              dense
              outlined
              :items="roleList"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
            >
            </v-select>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Gudang
            </v-card-title>
            <v-select
              v-model="olahData.managed_gudang"
              dense
              outlined
              :items="gudangList"
              item-text="name"
              item-value="id"
              chips
              multiple
              :rules="[rules.required]"
            >
            </v-select>

            <v-card-title
              class="pa-0 text-subtitle-1 font-weight-bold black--text"
            >
              Set Status
            </v-card-title>
            <v-select
              v-model="olahData.is_activated"
              dense
              outlined
              :items="status"
              item-text="text"
              item-value="id"
              :rules="[rules.required]"
            >
            </v-select>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeOlahData"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="edit"
            :disabled="!valid"
            :loading="loading"
          >
            Edit & Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="500">
      <v-card color="red" rounded>
        <v-card-title class="text-h6 white--text">
          Data Gagal Ditambahkan !!
        </v-card-title>
        <v-card-title class="text-caption white--text">
          Periksa kembali inputan anda. Email user tidak boleh sama dengan user
          yang lain.
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    olahDataDialog: Boolean,
    olahData: Object,
    roleList: Array,
    gudangList: Array,
  },

  data: () => ({
    valid: false,
    loading: false,
    popDialog: false,
    newPassword: "",
    datas: {},
    put: {},
    rules: {
      required: (value) => !!value || "Tidak boleh kosong !!",
      minReq: (value) => value.length >= 6 || "Min 6 karakter",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "e-mail tidak valid !!";
      },
    },
    status: [
      { id: 1, text: "Active" },
      { id: 2, text: "Disabled" },
    ],
  }),

  methods: {
    edit() {
      this.loading = true;
      this.datas = {};
      this.datas.id = this.olahData.id;
      this.datas.name = this.olahData.name;
      this.datas.email = this.olahData.email;
      this.datas.role_id = this.olahData.role_id;
      this.datas.passw = this.newPassword;
      if (this.olahData.is_activated === 2) {
        this.datas.is_activated = 0;
      } else {
        this.datas.is_activated = 1;
      }
      const dataSend = JSON.stringify(this.datas);
      var put = {};
      var list = [];
      put.id = this.olahData.id;
      this.olahData.managed_gudang.forEach((v) => {
        if(v.name === undefined){
          list.push(v);
        }else{
          list.push(v.id);
        }
      })
      put.list_gudang = list;
      const putSend = JSON.stringify(put);
      axios
        .put("v1/admin/user_web", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            axios
              .put("v1/admin/user_web/gudang", putSend, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(() => {
                this.loading = false;
                this.$emit("reloadDataEdit");
                this.closeOlahData();
              });
          }
        })
        .catch(() => {
          this.popDialog = true;
        });
    },
    closeOlahData() {
      this.newPassword = "";
      this.$refs.form.resetValidation();
      this.$emit("closeOlahDataDialog");
    },
    closePopDialog() {
      this.loading = false;
      this.popDialog = false;
    },
  },
};
</script>